/* Base */

html {
  font-family: $basic;
  font-weight: normal;
  font-size: 1rem;
  font-display: swap; }
body {
  width: 100%;
  max-width: 100% !important;
  color: lighten($card,2%);
  font-size: 80%;
  font-family: $basic;
  font-weight: normal;
  font-smooth: always;
  overflow-x: hidden;
  letter-spacing: .05em;
  -webkit-font-smoothing: antialised;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
  overflow-x: hidden;
  @include v-xs {
    max-width: 100% !important;
    overflow-x: hidden !important; } }

summary {
  width: 200px !important;
  @include v-xs {
    width: 180px !important; }
  &::marker {
    color: $card; } }

a {
  color: inherit !important;
  text-decoration: none !important; }

.app {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between; }

.hero {
  display: flex;
  justify-content: center;
  height: 100vh;
  @include v-sm {
    height: auto; } }

.cta {
  background-color: #4263eb; }

.site-title {
  @include v-xs {
    zoom: .85; } }

.navbar {
  background-color: #EDF2FF; }

.loading {
  font-size: 1.5rem; }

#navbarNav {
  @include v-sm {
    position: fixed;
    display: block;
    padding: 24px;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $lite;
    transition: all ease-out .7s;
    line-height: 2;
    &.collapsed {
      visibility: visible;
      left: 0;
      .nav-link {
        text-align: center; }
      .job-link {
        margin-top: 20px !important; } }
    &.collapse {
      visibility: hidden;
      left: 120%; } } }

.page-title {
  position: relative;
  background-color: #EDF2FF;
  padding: 10em 10em 0;
  min-height: 70vh;
  @include v-sm {
    padding: 10em 2em 0 !important; }
  &.mini {
    min-height: auto; }
  &:after {
    position: absolute;
    margin-bottom: 20vh;
    content: '';
    top: 100%;
    left: 0;
    display: block;
    width: 100%;
    height: 30vh;
    background-color: #EDF2FF;
    border-radius: 0 0 100% 100%;
    @include v-md {
      height: 15vh;
      border-radius: 0 0 150% 150%; } }
  @include v-xs {
    .w-70 {
      min-width: 90% !important; } } }

button {
  &.navbar-toggler {
    padding-top: 0;
    @include v-sm {
      transform: translate(10px, 21px) !important; }
    @include v-xs {
      transform: translate(0, 14px) !important; }
    span {
      filter: invert(100%);
      @include v-sm {
        zoom: 1.4; }
      @include v-xs {
        zoom: 1.25; } } } }

.navbar-brand {
  p {
    @include v-xs {
      margin-bottom: 0; } } }

.navbar-toggler {
  border: none !important;
  box-shadow: none !important;
  transform: translate(4px,16px) !important; }

.cta-app,
.footer {
  background-color: #EDF2FF;
  .nav-link {
    font-size: 1.1em !important; }
  * {
    @include v-sm {
      text-align: center !important; } }
  .nav-item {
    @include v-sm {
      margin-bottom: 20px;
      width: 100% !important;
      display: block !important; } } }

.cta-app {
  @include v-sm {
    height: auto !important;
    min-height: 130vh; } }

.country {
  width: 100%;
  height: 320px;
  object-fit: scale-down;
  border-radius: 4px; }

table {
  margin: 40px auto;
  background-color: lighten($light, 5%);
  td, th {
    font-size: 14px;
    padding: 5px 20px !important;
    border: 2px solid $white; } }

.page-pr {
  * {
    color: $card !important;
    font-size: 14px !important;
    line-height: 1.5; }
  a {
    color: $theme !important;
    text-decoration: none !important; }
  h3,
  h2 {
    font-size: 18px !important;
    margin: 20px auto;
    font-weight: 600; } }

.form-control,
select,
button[type="submit"],
button[type="button"] {
  line-height: 40px !important;
  font-size: 14px !important; }

button[type="submit"] {
  margin-left: -24px !important; }

ul {
  list-style-type: circle; }

.job-link {
  margin-top: 2px;
  margin-left: 16px;
  padding-bottom: 8px;
  color: $card;
  border-radius: 8px;
  border: 2px solid $theme;
  background-color: $white;
  &:hover {
    background-color: $theme;
    color: $white !important; } }

.btn-primary {
  background-color: darken($theme, 15%);
  border-color: darken($theme, 15%); }

.btn-primary:hover {
  background-color: darken($theme, 20%);
  border-color: darken($theme, 20%); }

.navbar-brand {
  .logo {
    display: block;
    margin-left: -1px;
    margin-top: 30px;
    margin-bottom: -16px;
    font-size: 28px;
    font-weight: 700;
    line-height: 0;
    letter-spacing: .025em; }
  .slogan {
    font-size: 13px;
    font-weight: 700; } }

.intro-subtitle {
  font-size: 20px;
  line-height: 1.5;
  @include v-sm {
    font-size: 18px; } }

.intro-title {
  font-size: 54px;
  font-weight: 800;
  @include v-sm {
    font-size: 32px; } }

.screens {
  position: relative;
  width: 80%;
  height: 100%;
  object-fit: cover;
  margin: 3em auto -24em;
  z-index: 1;
  &.w-70 {
    @include v-md {
      min-width: 110% !important;
      margin: -3em auto -10em; }
    @include v-xs {
      min-width: 110% !important;
      margin: -5em auto -15em; } } }

.footer {
  .mx-4 {
    @include v-xs {
      margin: 0 0 20px !important; } } }

.title {
  &-big {
    font-size: 5em;
    font-weight: 800;
    @include v-sm {
      font-size: 40px;
      width: 100% !important; }
    @include v-xs {
      margin: -24px auto 24px !important;
      padding: 0 !important;
      font-size: 21px;
      min-width: 100% !important; } }
  &-large-cta {
    font-size: 5em;
    font-weight: 500;
    @include v-sm {
      font-size: 24px;
      width: 100% !important; } }
  &-large {
    font-size: 3em;
    font-weight: 700;
    @include v-sm {
      font-size: 24px;
      width: 100% !important; }
    @include v-xs {
      font-size: 18px;
      width: 100% !important; } }
  &-chapter {
    font-size: 1.75em;
    font-weight: 600;
    @include v-sm {
      font-size: 24px;
      width: 100% !important; } }
  &-medium {
    font-size: 1.65em;
    margin: 2em 4em;
    line-height: 1.5;
    font-weight: 500;
    opacity: 0.75;
    &.w-60,
    &.w-80 {
      @include v-sm {
        margin: 24px 0 !important;
        font-size: 14px;
        width: 100% !important; } } }
  &-small {
    font-size: 1.25em;
    font-weight: 400;
    line-height: 1.75;
    @include v-sm {
      font-size: 16px;
      width: 100% !important; }
    @include v-xs {
      font-size: 14px;
      line-height: 1.5;
      width: 100% !important; } }
  &-prices {
    font-size: 1.15em;
    font-weight: 400;
    @include v-sm {
      font-size: 16px;
      width: 100% !important; } }
  &-mini {
    font-size: 1em;
    font-weight: 400; }
  &-rev {
    font-size: 14px;
    line-height: 1.5; } }

.w-60 {
  width: 60%;
  @include v-sm {
    min-width: 96% !important;
    margin-left: auto;
    margin-right: auto; } }

h3.title-medium.c-medium.px-md-3.w-60.ma.au,
.title-medium.text-center.w-80 {
  @include v-sm {
    min-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    font-size: 14px;
    font-weight: 400;
    margin-top: 24px;
    margin-bottom: 24px; } }

.row {
  .mx-5 {
    @include v-xs {
      text-align: center !important;
      margin-left: 0 !important;
      margin-right: 0 !important; } } }

.ma {
  margin-left: auto;
  margin-right: auto; }

img {
  @include v-md {
    max-width: 100% !important; } }

.container {
  @include v-md {
    max-width: 96% !important;
    width: 96% !important;
    min-width: 96% !important;
    margin-left: 2% !important;
    margin-right: 2% !important; } }

.m {
  &-hide {
    @include v-sm {
      display: none !important; } }
  &-show {
    display: none;
    @include v-xs {
      display: inline-flex;
      transform: translate(-20px,13px);
      zoom: .85; } } }

.slide {
  &.logo-slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 3%;
    width: 100%;
    margin: 0 auto;
    height: 64px;
    opacity: 1;
    transition: all ease-out .5s;
    .logo-slider {
      position: absolute;
      top: 0;
      left: auto;
      right: auto;
      display: block;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      max-height: 58px;
      object-fit: scale-down;
      object-position: center;
      transition: all ease-out .5s;
      &.theme {
        opacity: 1; }
      &.color {
        opacity: 0; }
      &:hover {
        &.theme {
          opacity: 0; }
        &.color {
          opacity: 1; } } } } }

.slick-prev,
.slick-next {
  &:before {
    color: $grey !important; } }

.slick-dots {
  margin-top: 40px;
  li {
    margin: 0 !important; } }

.slick-dots {
  li {
    button {
      &::before {
        color: $blue !important; } } } }

.slick-slide {
  img {
    display: block;
    width: 90% !important;
    padding: 0 !important;
    margin: 0 auto !important; } }

.a-hide {
  display: none !important;
  @include v-xs {
    display: block !important; } }

.m-hide {
  display: block !important;
  @include v-xs {
    display: none !important; } }

.mx-md-3 {
  @include v-xs {
    margin-left: .5em;
    margin-right: .5em;
    display: block !important;
    min-width: 53.725% !important; } }
.smallest-btn {
  @include v-xs {
    &.one {
      margin-left: .5em;
      margin-right: -0.5em; }
    &.two {
      margin-right: .5em;
      margin-left: -0.5em; } } }
