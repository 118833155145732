.title-price {
  font-size: 3em;
  font-weight: 700; }

.card {
  background-color: transparent !important;
  box-shadow: none !important;
  border-radius: 24px !important;
  overflow: hidden;
  transform: scale(1);
  transition: all ease-out .4s;
  border-width: 4px !important;
  &[radius='2'] {
    border-width: 5px !important;
    transform: scale(1.03); }
  &:hover {
    transform: scale(1.03); }
  &.active {
    border: 10px solid #f00 !important; }
  .ttu {
    text-transform: uppercase; }
  &-body {
    padding: 0 !important; }
  .btn {
    text-transform: uppercase; }
  li {
    border-color: #D8F3FF !important;
    span {
      font-weight: 500 !important;
      color: #666 !important;
      &.title-mini {
        font-size: 1.025em;
        font-weight: 700 !important;
        color: #1c9524 !important; } } } }

.card-title {
  margin: 0 !important;
  padding: 16px 0 !important; }

.c-price {
  .small {
    font-size: .5em !important; } }

.tab-pricing {
  margin-bottom: 40px;
  background-color: #ffffff00;
  border: none;
  color: $blue;
  font-size: 1.25em;
  font-weight: 700;
  padding: 12px;
  border-bottom: 2px solid #ffffff00;
  &.active {
    color: $card;
    font-weight: 600;
    border-bottom: 2px solid $blue;
    &:hover {
      cursor: default; } } }
