.bg-all {
  background-color: #f9fafa; }

.title-page {
  margin-bottom: 6px;
  font-size: 1.85em;
  font-weight: 800;
  @include v-sm {
    font-size: 24px; } }

.section-page {
  margin: 40px auto;
  padding: 0 10%;
  font-size: 110% !important;
  @include v-xs {
    margin: 120px auto;
    padding: 0; }
  * {
    font-size: 1em !important;
    zoom: 1;
    line-height: 1.5; }
  p,
  ul,
  li {
    margin-top: 16px; }
  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin: 40px 0 20px; }
  h3 {
    font-size: 1.2em; } }

.page-pr {
  @include v-xs {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%; }
  * {
    @include v-xs {
      word-wrap: break-word; } } }

table {
  @include v-sm {
    thead,
    tr,
    td {
      display: block !important; } } }

.form {
  .col {
    @include v-xs {
      display: block;
      min-width: 100% !important; } } }

button.w-30 {
  @include v-xs {
    display: block;
    min-width: 100% !important;
    margin: 0 !important; } }

.card-job {
  @include v-sm {
    width: 50%; }
  @include v-xs {
    width: 90%;
    margin-left: auto;
    margin-right: auto; } }

.subs-input {
  width: 220;
  border-radius: 8px 0 0 8px;
  padding-left: 16;
  margin: 0 20px 0 -20px;
  @include v-xs {
    width: 220px !important;
    border-radius: 8px;
    margin: 0 auto !important; } }
.subs-button {
  width: 140;
  border-radius: 0 8px 8px 0;
  margin: -1px -20px 1px 20px;
  @include v-xs {
    min-width: 220px !important;
    max-width: 220px !important;
    border-radius: 8px;
    margin: 0 auto 20px !important;
    transform: translateX(20px); } }

.m-block {
  .cats {
    display: inline-block;
    border: 2px solid $theme;
    &:hover {
      cursor: pointer; } } }

.footer {
  @include v-xs {
    height: auto; }
  .flex {
    @include v-xs {
      display: block; } }
  .nav-link {
    @include v-xs {
      display: block;
      min-width: 100% !important;
      margin-bottom: 24px;
      text-align: center;
      clear: both; } } }

.top-10 {
  @include v-sm {
    margin-top: 16px; } }

.card-job {
  .card-header {
    background-color: #f9ecff; }
  &:nth-child(3n+1) {
    .card-header {
      background-color: #fcf9e2; } }
  &:nth-child(3n+2) {
    .card-header {
      background-color: #f1ffff; } } }

.page-title {
  .w-60 {
    @include v-md {
      width: 100%; } } }
.page {
  * {
    font-size: 1em !important; }
  strong {
    font-weight: 600 !important; }
  a {
    font-size: 0.915em !important;
    color: $blue !important;
    &:hover {
      color: $dark !important; } } }
