$red: #FA7375;
$light: #EDEDED;
$lite: #fdfdfd;
$grey: #B9B9B9;
$pink: #FE3E87;
$dark: #282828;
$page: #515151;
$card: #3e4258;
$theme: #22b3de;
$blue: #0C8EC5;
$lblue: #7BD2EB;
$marine: #13DBE8;
$white: #fff;
$black: #000;
$back: #10264A;
$line: #0a1c39;
$btn: #a2dcf7;

$price: #0C8EC5;
$green: #98ac18;

$basic: 'Inter Tight', sans-serif;

.bg-btn {
  border: 2px solid $theme; }

.au {
  animation: Up 1.7s .4s; }
.ad {
  animation: Down 1.7s .4s; }
.al {
  animation: Left 1.7s .4s; }
.ar {
  animation: Right 1.7s .4s; }

@keyframes Right {
  from {
    transform: translateX(60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Left {
  from {
    transform: translateX(-60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Up {
  from {
    transform: translateY(60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@keyframes Down {
  from {
    transform: translateY(-60px);
    opacity: 0; }
  to {
    transform: translateY(0);
    opacity: 1; } }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content; }
  &:-moz-placeholder {
    @content; }
  &::-moz-placeholder {
    @content; }
  &:-ms-input-placeholder {
    @content; } }

.acss {
  background: linear-gradient(249deg, #eaf4fe, #fdecf9, #fcfae4, #fff4f8);
  background-size: 300% 300%;
  animation: Grd ease-in-out 8s infinite; }

@keyframes Grd {
  0% {
    background-position: 0% 78%; }
  50% {
    background-position: 100% 23%; }
  100% {
    background-position: 0% 78%; } }
